<template>
  <div>
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">

        <div class="col-sm-2">
          <label for="">Фильтрация</label>
          <a-select
              mode="multiple"
              placeholder="Фильтрация"
              v-model="dataFilter.type"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
            >
            <a-select-option v-for="type in filterType" :key="type.value" :value="type.value" :label="type.title">
              {{type.title}}
            </a-select-option>
          </a-select>
        </div>

        <div class="col-sm-5">
          <label for="">Офисы</label>
          <a-select
              mode="multiple"
              placeholder="Офисы"
              v-model="dataFilter.branches"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
            >
            <a-select-option v-for="branch in branches" :key="branch.id" :value="branch.id" :label="branch.title">
              {{branch.title}}
            </a-select-option>
          </a-select>
        </div>

        <div class="col-sm-5">
          <label for="">Водители</label>
          <a-select
              mode="multiple"
              placeholder="Выберите район"
              v-model="dataFilter.drivers"
              style="width: 100%"
              optionLabelProp="label"
              optionFilterProp="label"
              @change="applyFilter"
            >
            <a-select-option v-for="driver in drivers" :key="driver.id" :value="driver.id" :label="driver.name">
              {{driver.name}}
            </a-select-option>
          </a-select>
        </div>

      </div>
    </div>
    <mini-loader v-if="load" />

    <gmap-map
      :path="center"
      :center="center"
      :zoom="5"
      style="width:100%;  height: 500px;"
      :clickable="true"
      >
      <gmap-marker
        v-if="visibleBranches"
        v-for="branch in filteredBranches"
        :title="branch.title"
        :key="'branch'+branch.id"
        :position="branch.position"
        :center="branch.position"
        :clickable="true"
        @click="toggleInfo(branch.title)"
      ></gmap-marker>
      <gmap-marker
        v-if="visibleDrivers"
        v-for="driver in filteredDrivers"
        :title="driver.name"
        :key="'driver'+driver.id"
        :position="driver.position"
        :center="driver.position"
        :icon="markerOptions"
        :clickable="true"
        @click="toggleInfo(driver.name)"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>



<script>
const mapMarker = require('../../assets/images/dr.png');
import axios from 'axios';



export default {
  data(){
    return{
      markerOptions: {
        url: mapMarker,
        size: {width: 25, height: 30, f: 'px', b: 'px',},
        scaledSize: {width: 25, height: 30, f: 'px', b: 'px',},
        rotate: 40
      },

      center: {
        lat: 40.037134968296996,
        lng: 65.96757908734419
      },

      drivers: [],
      branches: [],
      dataFilter: {
        type: [],
        branches: [],
        drivers: []
      },
      filterType: [
        {
          title: 'Водители',
          value: 'drivers'
        },
        {
          title: 'Офисы',
          value: 'branches'
        }
      ],

      filteredDrivers: [],
      filteredBranches: [],

      load: false,
      visibleDrivers: true,
      visibleBranches: true,
    }
  },
  sockets: {
    connect() {
      this.isConnected = true;
      console.log('Connected !')
    },
    disconnect() {
      this.isConnected = false;
      console.log('Disconnected !')
    },
    updateLatLong(value){
      this.filteredDrivers.filter(driver => {
         if( driver.id == value.id ){
           driver.position.lat = Number(value.lat);
           driver.position.lng = Number(value.lng);
           driver.rotation = value.rotation;
         }
      });
      console.log(this.filteredDrivers);
    }
  },
  mounted(){
    this.getAll();
  },
  methods:{
    getAll(){
      this.load = true;
      axios.get('/employee/tracking').then((response) => {
        if(response.status == 200){
          this.branches = response.data.branches;
          this.drivers = response.data.drivers;
          this.filterBranches(this.branches);
          this.filterDrivers(this.drivers);
        }
      }).catch((error) => {
        if(error.response.status == 500){
          this.$message.error(
            "Ошибка сервера",
            3
          );
        }
      }).finally(() => {
        this.load = false;
      })
    },
    filterBranches(data){
      this.filteredBranches = [];
      for(let i=0; i<data.length; i++){
        var arr = {
          id: '',
          position: {
            lat: '',
            lng: ''
          },
          title: ''
        };
        arr.id = data[i].id;
        let points = data[i].point.split(',');
        arr.position.lat = Number(points[0]);
        arr.position.lng = Number(points[1]);
        arr.title = data[i].title;
        this.filteredBranches.push(arr);
      }
    },
    filterDrivers(data){
      this.filteredDrivers = [];
      for(let i=0; i<data.length; i++){
        var arr = {
          id: '',
          position: {
            lat: '',
            lng: ''
          },
          name: ''
        };
        arr.id = data[i].id;
        arr.position.lat = data[i].lat;
        arr.position.lng = data[i].lng;
        arr.name = data[i].name;
        this.filteredDrivers.push(arr);
      }
    },
    applyFilter(){
      if(this.dataFilter.type.length){
        this.visibleDrivers = false;
        this.visibleBranches = false;
        if(this.dataFilter.type.includes('drivers')){
          this.visibleDrivers = true;
        }
        if(this.dataFilter.type.includes('branches')){
          this.visibleBranches = true;
        }
      }else{
        this.visibleDrivers = true;
        this.visibleBranches = true;
      }
      if(this.dataFilter.branches.length){
        var br = this.dataFilter.branches;
        const result = this.branches.filter(function callbackFn(branch){
          for(let i=0; i<br.length; i++){
            if(branch.id == br[i]){
              return true;
            }
          }
          return false;
        });
        this.filterBranches(result);
      }else{
        this.filterBranches(this.branches);
      }
      if(this.dataFilter.drivers.length){
        var dr = this.dataFilter.drivers;
        const result = this.drivers.filter(function callbackFn(driver){
          for(let i=0; i<dr.length; i++){
            if(driver.id == dr[i]){
              return true;
            }
          }
          return false;
        });
        this.filterDrivers(result);
      }else{
        this.filterDrivers(this.drivers)
      }
    },
    toggleInfo(data){
      this.$message.info(
        data,
        3
      );
    },
  }
}
</script>

<style>

</style>
